import Product, { createById } from 'common/DataObjects/Product';
import MultipleTrackSetups from './MultipleTrackSetups';
import Track from './Track';
import moment from 'moment';
import { PRODUCT_IDS } from 'configs/products.ts';

export default class RaceDay {
    date = moment(new Date()).format('YYYY-MM-DD');
    raceDayDate = ''; // legacy adoption
    trackId = 0;
    trackName = '';
    track = {
        name: '',
        code: '',
    };
    canceled = false;
    firstRacePostTime = null;
    firstRacePostTimeUTC = null;
    fullProductList = [];
    products = [];
    raceCardAvailable = false;
    sportSystemId = 0;
    trot = false;
    mainProduct = null;
    multipleTrackSetups = {};
    raceNumbersByProducts = {};
    raceInfos = {
        raceInfos: [],
    };
    country = {
        code: '',
    };
    hasAnyResult = false;
    hasTrMediaTips = false;

    constructor(attributes) {
        return Object.assign(this, attributes);
    }

    hasMultitrackSetups = productId => {
        return !!this.multipleTrackSetups[productId];
    };

    /**
     * @return {Product[]}
     */
    getProducts = () => this.products;

    isSame = raceDay => {
        if (!raceDay) {
            return false;
        }
        return this.date === raceDay.date && this.track.code === raceDay.track.code;
    };

    isNull = () => !this.trackId;

    static unserialize = data => {
        const raceDay = new RaceDay();

        try {
            raceDay.date = data.raceDayDate;
            raceDay.raceDayDate = data.raceDayDate;
            raceDay.trackId = data.trackId;
            const track = { ...data.track, name: data.trackName };
            raceDay.trackName = data.trackName;
            raceDay.track = Track.unserialize(data.trackId, track);
            raceDay.canceled = data.canceled;
            raceDay.firstRacePostTime = data.firstRacePostTime;
            raceDay.firstRacePostTimeUTC = data.firstRacePostTimeUTC;
            raceDay.fullProductList = data.fullProductList;
            raceDay.multipleTrackSetups = MultipleTrackSetups.unserialize(data).data;

            raceDay.products = data.betTypes.betTypes
                .map(product => {
                    const productObject = product.name;

                    if (
                        !data.availableForBetting.some(
                            productId => productId === productObject.code
                        )
                    ) {
                        return null;
                    }

                    const p = Product.unserialize(
                        productObject,
                        raceDay.hasMultitrackSetups(productObject.code),
                        raceDay
                    );

                    raceDay.raceNumbersByProducts[p.id] = product.races;
                    // Back-End doesn't return raceNumbersByProducts for VP
                    // thus we have to "polyfill" it on FE side
                    if (p.id === PRODUCT_IDS.V) {
                        raceDay.raceNumbersByProducts[PRODUCT_IDS.VP] = product.races;
                    }

                    return p;
                })
                .filter(product => product !== null);

            const winnerProduct = raceDay.products.find(p => p.id === PRODUCT_IDS.V);

            if (winnerProduct && data.fullProductList.includes(PRODUCT_IDS.VP)) {
                raceDay.products.push(createById(PRODUCT_IDS.VP));
            }

            raceDay.raceCardAvailable = data.raceCardAvailable;
            raceDay.sportSystemId = data.sportSystemId;
            raceDay.trot = data.trot;
            raceDay.mainProduct = Product.unserialize(
                data.mainBetType,
                raceDay.hasMultitrackSetups(data.mainBetType.code),
                raceDay
            );
            raceDay.programNumber = data.programNumber;
            raceDay.country = data.country;
            raceDay.hasAnyResult = data.raceInfos?.raceInfos.some(info => info.hasResult);
            raceDay.racesInfo = data.raceInfos?.raceInfos ?? [];
            raceDay.hasTrMediaTips = data.hasTrMediaTips;
        } catch (error) {
            console.error(error);
            //@TODO Logger of errors
        }

        return raceDay;
    };
}
