import React, { Component } from 'react';
import { RACE_INFO_SERVICE } from 'configs/rest';
import icon from 'images/icons/downloadpdf.svg';

import getTexts from 'utils/localization';
import { byPlatform } from 'utils/platforms';
import {
    DownloadButton,
    DownloadButtonIcon,
    DownloadButtonLabel,
} from 'features/RaceCard/PdfDownload/styled';
import PdfDownloadMobileView from './PdfDownloadMobileView';
const t = getTexts();

class BlobPdf extends Component {
    constructor() {
        super();

        this.state = {
            iframeCode: '',
        };
    }

    downloadPdf = async () => {
        let { trackId, date } = this.props.getSelectedDayData(this.props.AISDataProvider);

        if (trackId && date) {
            let linkToFile = RACE_INFO_SERVICE(trackId, date);
            try {
                const response = await fetch(linkToFile, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = `banprogram-${trackId}_${date}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
            } catch (error) {
                console.error('Error downloading the PDF:', error);
            }
        } else {
            console.log('raceDay is null');
        }
    };

    render() {
        return byPlatform(
            <PdfDownloadMobileView
                action={this.downloadPdf}
                title={t.PdfDownload.courseProgram}
                description={t.PdfDownload.courseProgramDesc}
            />,
            <div>
                <DownloadButton onClick={this.downloadPdf}>
                    <DownloadButtonLabel>{t.PdfDownload.courseProgram}</DownloadButtonLabel>
                    <DownloadButtonIcon>
                        <img src={icon} alt="Download PDF" />
                    </DownloadButtonIcon>
                </DownloadButton>
                {this.state.iframeCode}
            </div>
        );
    }
}

export default BlobPdf;
