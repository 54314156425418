import { HIDE_STREAM, SHOW_STREAM, START_STOP_CAST, SET_AUTOPLAY } from './actions';

const initialState = {
    isFloatingStreamShown: false,
    eventSource: '',
    isCastRunning: false,
    autoplay: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_STREAM: {
            return {
                ...state,
                isFloatingStreamShown: true,
            };
        }
        case HIDE_STREAM: {
            return {
                ...state,
                isFloatingStreamShown: false,
            };
        }
        case START_STOP_CAST: {
            return {
                ...state,
                isCastRunning: action.payload,
            };
        }
        case SET_AUTOPLAY: {
            return {
                ...state,
                autoplay: action.payload,
            };
        }
        default:
            return { ...state };
    }
};

export default reducer;
