export default {
    title: 'Bet25 Heste',
    domain: 'derby25.dk',
    version: 'Version',
    upgrade: 'Opdater til seneste version',
    meta: {
        title: 'Danmarks Nye Millionspil: Spil & Vind på Heste | Bet25 Heste',
        description:
            'Bet25 heste | Spil på trav og galop. Vær med i jagten om store millionpuljer hver eneste uge. Store kontante gevinster og livestreaming af løb.',
    },
    home: 'Hjem',
    odds: 'odds',
    lottery: 'Lynspil',
    spilklub: 'SpilKlub25',
    news: '25-nyt',
    history: 'Mine spil',
    live: 'Live stream',
    result: 'Resultat',
    login: 'Log ind',
    signup: 'Opret konto',
    cprNumber: 'CPR-nr.',
    next: 'Næste',
    done: 'Færdig',
    back: 'Tilbage',
    readMore: 'Vis mere',
    loadMore: 'Vis mere',
    LOGINFIRST: 'Du skal logge ind først',
    LOGINFIRST_HISTORY: 'Du skal logge ind for at se dine væddemål',
    COUPONFIRST: 'At starte game scan kupon eller vindende kupon',
    sessionExpired: 'Din session er udløbet. Log venligst ind igen.',
    currency: 'Kr.',
    selfExclusionNotification: 'Din selvudelukkelse er aktiv frem til %s kl. %s',
    menu: {
        faqTitle: 'FAQ',
        termsTitle: 'Regler og vilkår',
        faq: `Her i vores FAQ finder du hjælp til langt de fleste af
                        dine spørgsmål. Hvis du mod forventning ikke kan finde
                        svar på dit spørgsmål her, så klik på Kontakt Os
                        herover, hvor du bla. har mulighed for at chatte med
                        vores kundemedarbejdere.`,
        live: 'Live',
    },
    Footer: {
        label: 'Udbydes af Bet25 heste',
        text: `Bet25 heste drives af 25syv A/S · Mariane Thomsens Gade, 8000 Aarhus C. · CVR-nr. 30897765. 25syv A/S ejes af Ecosys A/S og kontrolleres af Spillemyndigheden. © %d, 25syv A/S.`,
        crossSales: {
            title: 'Med en 25-konto har du også adgang til',
            link: 'Gå til',
            bet25: {
                title: 'Sportsbetting',
                text: `Spil på bl.a. Champions League, Superliga eller faste odds
                        på hestevæddeløb`,
                alt: 'Bet25 logo',
            },
            rod25: {
                title: 'Casino & Jackpots',
                text: `Spil Live Casino eller prøv vores store udvalg med 450 spilleautomater.`,
                alt: 'Rod25 logo',
            },
        },
        //'Vi bruger cookies som en hjælp til at optimere pbrugeroplevelsen samt give en mere sikker oplevelse og måle trafik. Herudover bennyttes cookies med henbik på at vise individualiseret marketing indhold.',
    },
    SubscribeForm: {
        subscribeButtonText: 'Tilmeld',
        subscribeInputPlaceholder: 'Tilføj din e-mail...',
        errorMessage: 'Forkert email',
        successMessage: 'Du er blevet abonneret med succes',
    },
    signupWindow: {
        step1: {
            text: 'Dit personnummer anvendes for at bekræfte din alder og identitet.',
            subHeader: 'Har du allerede en 25-konto?',
            subText:
                'Hvis du allerede har en konto på Bet25 eller Bet25 Casino skal du blot logge ind med samme brugernavn og kodeord',
            link: 'her',
        },
        step2: {
            text: 'Vidste du, at du kan hente Nem ID appen og dermed slippe for at bruge fysiske nøglekort?',
        },
        step3: {
            text1: 'Personlige oplysninger',
            text2: 'Tryk på krydset, hvis du ønsker at ændre i dine oplysninger.',
        },
        step4: {
            title: 'Konto oplysninger',
            mobile: 'Mobil nr.',
            mobileRule: 'Min. 8 tal',
            email: 'E-mail',
            username: 'Brugernavn',
            usernameRule: 'Min. 6 tegn',
            password: 'Kodeord',
            passwordRule: 'Min. 6 tegn, 1 stort bogstav og 1 tal',
            securityQuestion: 'Vælg sikkerhedsspørgsmål',
            securityAnswer: 'Svar på sikkerhedsspørgsmål',
            expectedBet: 'Forventet månedlig spilleomfang',
            newslater: 'Ja tak, til at modtage tilbud og kampagner?',
            acceptRules: 'Jeg accepterer %, samt regler for %.',
            rules: 'vilkår og betingelserne',
            bonusCode: 'Bane kode (ikke påkrævet)',
            errors: {
                acceptRules: 'Accepter betingelser',
                securityQuestion: 'Skal udfyldes "sikkerhedsspørgsmål"',
                expectedBet: 'Skal udfyldes "forventet spilleomfang"',
            },
        },
        validation: {
            password: 'Ugyldig værdi',
        },
    },
    sidebar: {
        results: 'Resultat',
        contact_us: 'Kontakt os',
        resulted_cube: 'Replays',
        tip_suggestions: 'Tips forslag',
        responsible_game: 'Ansvarligt spil',
        terms_conditions: 'Regler og vilkår',
        about_derby25: 'Om Bet25 heste',
        report_us: 'Anmeld os på trustpilot',
        faq: 'FAQ',
        saved_coupons: 'Gemte kuponer',
        payout: 'Tilbagebetalingsprocent',
        footer: {
            line1: 'Version',
            line2: 'Udbydes af Bet25 heste',
        },
        news: 'Nyheder',
    },
    search: {
        placeholder: 'Søg på  hest, lockey eller bane...',
        button: 'Søg',
        cancel: 'Annuller',
    },
    userArea: {
        top: {
            lastLogin: 'Sidste login',
            deposit: 'Indbetaling',
            payout: 'Udbetaling',
        },
        bottom: {
            userId: 'Bruger-id',
            lastlogin: 'Sidste log-ind',
        },
        nav: {
            pinLogin: 'Pin login',
            changePwd: 'Dine oplysninger / Skift kodeord', // Dine oplynsninger / Skift kodeord
            profile: 'Dine oplysninger', // Dine oplynsninger / Skift kodeord
            transactions: 'Kontohistorik', // Transaktioner
            selfExclusion: 'Selvudelukkelse', // Selvudelukkelse
            depositLimits: 'Indbetalingsgrænse', // Indbetalingsgrænse
            notifications: 'Notifikationer', // Notifikationer
            subscriptions: 'Nyhedsbrev', // Nyhedsbrev
            logOut: 'Log ud', // Log ud
            deposit: 'Deposit',
            withdraw: 'Withdrawal',
            bonus: 'Bonus',
            activeBets: 'Aktive spil',
            finishedBets: 'Afsluttede spil',
            retailBets: 'Butik',
            voucher: 'Voucher',
            newsletter: 'Nyhedsbrev',
            desktop: {
                header: 'Tilmeld dig Bet25 heste nyhedsbrev',
                text1: 'Med nyhedsbreve modtager du tilbud, kampagner og konkurrencer via mail samt nyheder indenfor hestevæddeløb.',
                text2: 'Du kan til enhver tid framelde dig nyhedsbrevet på vores hjemmeside, på mobilen eller via mail.',
            },
        },
        newsletter: {
            header: 'Vil du modtage tilbud og kampagner?',
            text: 'Ved tilmelding gives tilladelse til at Bet25 A/S (herunder Bet25 Sport, Bet25 Casino, Bet25 Heste og Casino Go) må behandle oplysninger om mig og sende mig nyheder og markedsføring af vores produkter, kampagner og konkurrencer via e-mail, sms og sociale medier. Du kan altid tilbagekalde dit samtykke på Bet25.dk/sport, Bet25.dk/casino, og Bet25.dk/heste, samt via vores apps eller via link i nyhedsbreve. Læs mere om regler for beskyttelse af personlige oplysninger her: ',
            link: 'Vores persondatapolitik',
            label: 'Ja tak',
            forcedPermissionHeader: 'Samtykke i forbindelse med Køb',
            forcedPermissionText:
                'Hvis ikke du ønsker at modtage tilbud eller nyheder om lignende produkter (væddemål og online casino fra 25Syv A/S, herunder Bet25 Sport, Bet25 Casino, Bet25 Heste og Casino Go) via din oplyste e-mailadresse, skal du fjerne markeringen. Du kan til enhver framelde dig at modtage tilbud og nyheder fra 25Syv A/S igen. Læs mere om regler for beskyttelse af personlige oplysninger her:',
        },
        deposit: {
            title: 'Indbetaling',
            doDeposit: 'Indbetal',
            minAmount: 'Minimum beløb er ',
            next: 'Næste',
            success: 'Din indbetaling er gennemført.',
            error: 'Der opstod en fejl, prøv venligst igen.',
            fundErrors: {
                LOGINFIRST: 'Du skal logge ind først',
                DEPOSIT_CANCEL: 'Indbetaling annulleret',
                DEPOSIT_INTERNALERROR:
                    'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter',
                DEPOSIT_DECLINED_DKK_TEMP_MAX: 'Du har overskredet din maximum indbetaling',
                DEPOSIT_PPT_DECLINED: 'Indbetaling afvist',
                DEPOSIT_PPT_INTERNAL:
                    'Systemfejl - prøv igen og kontakt kundesupport hvis problemet fortsætter',
                DEPOSIT_PPT_DAYMAX: 'Du har overskredet din daglige maximumsindbetalingsgrænse',
                DEPOSIT_PPT_WEEKMAX: 'Du har overskredet din ugentlige maximumsindbetalingsgrænse',
                DEPOSIT_PPT_MONTHMAX: 'Du har overskredet din månedlige maximumsindbetalingsgrænse',
            },
            bonusAgreement: 'Ja tak, jeg vil gerne have min bonus:',
            bonusMessageLine1:
                '%d %s bonus på din indbetaling op til %d kr. i bonus. Minimum indbetaling er %d kr.',
            bonusMessageLine2:
                'Indbetaling og bonus skal tilsammen omsættes én (1) gang på Bet25 heste, før du kan udbetale.',
        },
        voucher: {
            title: ' Indtast og modtag!',
            description: `Har du modtaget en voucher, kan du indløse den herunder. Du kan se regler og vilkår på din voucher.`,
            success: `inkluderet en voucher på %d kr.`,
            inputSerial: 'Indtast nummer',
            inputCode: 'Indtast kode',
            button: 'indløs',
        },
        withdraw: {
            title: 'Udbetaling',
            minAmount: 'Minimum beløb er %d Kr.',
            next: 'næste',
            success: `Udbetalingen overføres automatisk til din NemKonto inden for 1-2 bankdage.`, //The payment will be automatically made to your account, and it will take 3-5 business days before the money is in your account
            tempUser: `Du kan ikke udbetale fordi din konto stadig kun er
                    midlertidig.
                    Da vi ikke har kunnet verificere din konto igennem NEMID,
                    skal vi bede dig indsende et digitalt foto af dit gyldige
                    sygesikringsbevis. Dette skal sendes til support@bet25.dk.`,
            // You can not pay because your account is still temporary only. Since we were unable to verify your account through NEMID, we would ask you to submit a digital photo of your valid health insurance certificate. This must be sent to support@bet25.dk
            smallAmount: 'Mindste udbetaling er %d kr.', // Minimum amount of withdrawal request is 50 kr.
            unavailableAmount: 'Saldoen er for lav',
            rollover: {
                yourBalance: `Din saldo er %d kr. inkluderet en bonus på %d kr.`, //`Your balance is %d DKK included a bonus of %d kr.`,
                voucher: `inkluderet en voucher på %d kr.`, //included a voucher of %d kr.
                minimumOdds: `Før du kan foretage en udbetaling skal du
                omsætte for mindst %d kr. til minimum odds %d.`,
            },
            oops: `OBS!`,
            tempRegistrationWarning: `Vi mangler at få verificeret din konto.
                Da din konto ikke blev automatisk verificeret, skal vi bede dig
                   indsende et foto af dit gule sundhedskort til
                   support@bet25.dk. Ellers lukkes din konto efter 30 dage.`,
            /**
             * We need to verify your account.
                              Since your account was not automatically verified, we should ask you
                                 Submit a photo of your yellow health card to
                                 support@bet25.dk. Otherwise your account will be closed after 30 days.
             */
        },
        newsletterUnsubscribed: `Du er nu afmeldt nyhedsbrevet og vil ikke
                                 længere modtage det. Hvis du fortryder og
                                 gerne vil modtage gode tilbud, freebets og
                                 kampagner, så kan du tilmelde dig igen herover.`,
        newsletterSubscribed: `Du vil nu modtage nyhedsbreve med gode tilbud,
                               freebets og kampagner.`,
        profile: {
            profileSuccess: 'Profilen er blevet opdateret.',
            changePwdSuccess: 'Kodeord er blevet opdateret.',
            changePwdExplanation: 'Skift din adgangskode til Bet25 heste',
            currentPwdPlaceholder: 'Nuværende kode',
            updateProfileSubmit: 'Ret',
            newPwd: 'Ny kode',
            pwdConfirm: 'Gentag ny kode',
            pwdConfirmError: 'Passwords matcher ikke',
            pwdSubmit: 'Skift kodeord',
            pwdChatMark: `Hvis du ikke kan huske dit kodeord kan du
                          skrive til support@bet25.dk eller chatte med
                          os. Tryk her for at åbne chatten.`,
            pwdStartChat: 'Start chat',
            pwdErrors: {
                PASSWOLDINCORRECT: 'Den nuværende adgangskode er forkert',
                VALIDATION: 'Min. 10 tegn, 2 tal, 1 stort og 1 lille bogstav',
            },
        },
    },
    pin: {
        title: 'PIN login',
        tooltip: 'indtast pin kode',
        success: 'PIN login er blevet indstillet.',
        successChanged: 'Din PIN-kode er nu ændret',
        error: 'PIN kunne ikke indstilles',
    },
    pinLogin: {
        error: 'Pin er forkert. Forsøg tilbage: %d',
        loginCommonly: 'Log ind med email og adgangskode',
        attemptsReached: 'Du har nået dine forsøg.',
    },
    depositLimits: {
        title: 'indbetalingsgrænse',
        text1: `Hvis du hæver din indbetalingsgrænse vil der gå 24 timer før dette træder i kraft.`,
        text2: `Hvis du sænker din indbetalingsgrænse træder det i kraft med det samme.`,
        daily: 'Dagligt',
        weekly: 'Ugentligt',
        monthly: 'Månedligt',
        presentAmount: 'Nuværende',
        expires: 'Udløber',
        nextAmount: 'Næste',
        submit: 'Sæt',
        setLimitPlaceholder: 'Beløb',
    },
    selfExclusion: {
        title: 'Selvudelukkelse',
        rofusTitle: `Spær eller luk din konto`,
        rofusNote: `Du kan spærre din 25-Konto i en periode eller permanent. Har du penge på din konto, vil pengene blive på din 25-konto i perioden. Ønsker du pengene udbetalt, skal du udbetale dit indestående, inden du spærre din konto. Vælger du en endelig udelukkelse, udbetaler vi automatisk dit indestående og eventuelle gevinster fra igangværende spil, til din NemKonto.`,
        rofusNote1: `Husk, at du har mulighed for at registrere dig i ROFUS,
         hvilket er spillemyndighedens register over frivilligt udelukkede
          spillere.`,
        rofusNote2: `Ved at registrere dig i ROFUS, vil du automatisk blive blokeret
         og udelukket fra alle hjemmesider udbudt af
          autoriserede spiloperatører i Danmark.`,
        rofusLink: 'Yderligere oplysninger om ROFUS',
        periodText: 'Jeg ønsker at blive udelukket i:',
        checkboxText1: 'Jeg er indforstået med at min spillekonto lukkes i den valgte periode',
        checkboxText2:
            'Bekræft at du vil lukke din konto permanent. Din konto kan ikke genåbnes senere.',
        submit: 'sæt',
        success: 'Du vil blive udelukket i den valgte periode.',
    },
    welcome: 'Velkommen',
    registrationComplete: 'Din registrering er nu fuldført',
    explore: 'Kom igang' /* 'Udforsk', */,
    skip: 'Spring over',
    loginModal: {
        username: 'Brugernavn',
        password: 'Adgangskode',
        rememberUser: 'Husk brugernavn',
        forgetPassword: 'Glemt kodeord',
        createAccount: 'Opret konto',
        nemIdLogin: 'NemID log ind',
        nemIdLogin2: 'Log ind med NemID',
    },
    validation: {
        required: 'Skal udfyldes',
        numeric: 'Feltet bør kun indeholde cifre',
        alphabetic: 'Feltet må kun indeholde bogstaver',
        email: 'Ugyldig email',
        min: 'Mindste værdi ',
        max: 'Maksimal værdi ',
        minLength: 'Længde må ikke være mindre end ',
        maxLength: 'Længde må ikke overstige ',
        symbol: 'tegn',
        symbols: 'tegn',
        notValid: 'Ugyldig værdi',
        sameAs: 'Feltet skal bekræftes',
        minNumber: 'Mindste værdi %d',
        maxNumber: 'Maksimal værdi %d',
    },
    user: {
        firstName: 'Fornavn',
        lastName: 'Efternavn',
        address: 'Adresse',
        zip: 'Postnummer',
        city: 'By',
    },
    ForgotPassword: {
        title: 'Glemt kodeord',
        text1: 'Bare rolig. Du er hverken den første eller sidste der glemmer sit kodeord. For at få det tilbage skal du indtaste dit brugernavn og email. Husk at tjekke dit spamfilter!',
        username: 'Indtast brugernavn',
        email: 'Indtast e-mail',
        proceed: 'Nulstil kodekord',
        text2: 'Kan du ikke huske enten brugernavn eller mail? Så kan du kontakte os på support@bet25.dk eller kontakte os via chatten her.',
        startChat: 'Start chat',
        success: {
            title: 'Din kode er nu sendt til din e-mail!',
            text: 'Husk at tjekke spamfilter, hvis du ikke finder den i første omgang. Ellers kan du altid kontakte os for hjælp.',
        },
        error: {
            title: 'Dit brugernavn eller kode er forkert.',
            text: 'Kontakt os enten via support@bet25.dk eller via chatten.',
        },
    },
    RacingCard: {
        winner: 'Vinder',
        raceType: 'Løbstype',
        distance: 'Distance',
        underlay: 'Underlag',
        startMethod: 'Startmetode',
        race: 'Løb',
        error: 'Kunne ikke vise racerkortet.',
        subHeader: {
            nr: 'Nr.',
            nrHorseCombined: 'Nr./Hest/Kusk',
            horseJockey: 'Hest/Kusk',
            horse: 'Hest',
            jockey: 'Kusk',
            trio: 'Placering',
            twin: 'Tvilling',
            komb: 'Plac.',
        },
        vRaceHeader: {
            nr: 'Afdeling',
            horse: 'Heste',
            selectAll: 'Vælg Alle',
            all: 'Vælg alle',
        },
        overview: 'Omsætning',
        overviewTitle: 'Omsætning',
        ongoing: 'Igang',
        postPosition: 'Tillæg: %s Meter',
        today: 'I dag',
        controlArea: {
            selectAll: 'Vælg alle',
            unselectAll: 'Fravælg alle',
            clearAll: 'Ryd kupon',
            statistic: 'Statistik',
            viewStats: 'Åbn stats',
            hideStats: 'Luk stats',
            reserve: 'Reserver',
        },
    },
    trackOverview: {
        length: 'Længde',
        width: 'Bredde',
        distance: 'Opløb',
        openStretch: 'Open stretch',
        code: 'Baneforkortning',
    },
    Shortcuts: {
        findTitle: 'Vælg spiltype',
        selectGame: 'Vælg spiltype',
        selectAvatar: 'Vælg avatar',
    },
    betSlip: {
        selectBetPrice: 'Vælg væddemål',
        track: 'Løb',
        place: 'Plac.',
        department: 'Afd.',
        nrAndHorse: 'Nr./Heste',
        winner: 'Vinder',
        topBarText: 'Bekræft dit spil',
        confirmBet: 'Spil %s',
        disabledBtn: 'vælg beløb',
        sum: 'Indsats',
        winAmount: 'Gevinst',
        minimumPicksError: 'Du skal vælge mindst én hest til hver afdeling',
        minimumPicksErrorWithSmartLyn:
            'Du skal vælge mindst én hest til hver afdeling eller lave en Smart Lyn',
        maximumPicksError: 'Du har valgt for mange heste',
        getAvailableCardsError: 'Racingkortet er ikke tilgængeligt til væddemål',
        performBetError: 'Der opstod en fejl under forsøget på at udføre et væddemål',
        cpLow: 'Kombinationsprisen er for lav',
        cpHigh: 'Kombinationsprisen er for høj',
        cpEvenNumbers: 'Ulige tal og decimal tal ikke tilladt.',
        Strict: {
            label: {
                V6: 'Spil kupon som V6',
                V7: 'Spil kupon som GS7',
            },
            info: {
                V65: 'Spiller du kuponen som V6 får du ikke gevinst, hvis du rammer 4 eller 5 rigtige. Til gengæld får du 2 x din indsats, hvis du rammer 6 rigtige',
                V64: 'Spiller du kuponen som V6 får du ikke gevinst, hvis du rammer 4 eller 5 rigtige. Til gengæld får du 2,5 x din indsats, hvis du rammer 6 rigtige',
                V7: 'Spiller du kuponen som GS7 får du ikke gevinst, hvis du rammer 5 eller 6 rigtige. Til gengæld får du 2,5 x din indsats, hvis du rammer 7 rigtige',
            },
        },
        win: 'Væddemål vundet',
        refund: 'Væddemål refunderet',
        lose: 'Væddemål tabt',
        canceled: 'Annulleret',
        selectedAll: 'Alle heste er valgt',
    },
    receipt: {
        goodLuck: 'Held & Lykke',
        message: 'Dit spil er nu placeret!',
        goLive: 'Gå til live',
        myGamesText: 'Held og lykke, du kan fremover finde kuponen under',
        myGamesLink: 'Mine spil.',
    },
    Transactions: {
        transactionsFor: 'Transaktioner fra:',
        chooseDate: 'Vælg dato',
        showFrom: 'VIS FRA',
        showTo: 'VIS TIL',
    },
    dataNotFound: 'Ingen data fundet',
    gamesNotFound: 'Ingen spil fundet',
    ShortStatistic: {
        coach: 'Træner',
        record: 'Rekord',
        shoe: 'Sko',
        money: 'Penge',
        start: 'Kr/start',
        starter: 'Starter livs',
        weight: 'Vægt',
    },
    DerbyFlash: {
        bayFlashGame: 'Køb kupon',
        currentPool: 'Nuværende pulje',
        restarts: 'Starter om',
        intro: 'Intro til Lynspil',
        description: 'Her kan du købe Lynspil spil til V-spil. Kort sagt minder det lidt om Lotto.',
    },
    Live: {
        authErrorMessage: 'Du skal være logget ind for at se LIVE-video',
        restrictionMessage:
            'For at se hestevæddeløb skal du foretage en indbetaling eller tilmelde dig Bet25 heste nyhedsbrev. Begge dele kan gøres under dine kontoindstillinger.',
        Desktop: {
            Section1: {
                title: 'Danske løb',
                description:
                    'På Bet25.dk/heste kan du følge alle løb fra de danske baner via Fast Track live-udsendelserne. Her vil der blive fokuseret på livereportager, kyndige spiltips og grundige analyser, så du som spiller bliver klædt godt på til at spille med og få en spændende sportsoplevelse. På Fast Track-kanalen vil der også blive vist genudsendelser fra de svenske løb.',
            },
            Section2: {
                title: 'Svenske løb',
                description:
                    'Ønsker du at følge med i de svenske løb, når du har købt en Lynspil eller har spillet på de store svenske puljer, kan du følge med i ATG Nordic live. Der fokuseres på kyndige spiltips og grundige analyser med svensk kommentering.',
            },
            Section3: {
                title: 'Se eller gense løb i vores arkiv',
                description:
                    'Vil du se hestens form inden du beslutter dig? Hvis du ønsker at se eller gense et løb, kan du søge efter hestens navn og finde løbet direkte. Alternativt kan du vælge landet du vil se løbet fra, banenavnet og derefter løbsdagen samt løbet.',
            },
        },
        Mobile: {
            Section1: {
                subtitle: 'Danske løb',
                title: 'Se Danske løb med Fast track',
                description:
                    'I Fast Track live-udsendelserne fokuseres på livereportager, kyndige spiltips og grundige analyser, så du som spiller bliver klædt godt på til at spille med og få en spændende sportsoplevelse.',
            },
            Section2: {
                subtitle: 'Svenske løb',
                title: 'Se Svenske og danske løb',
                description:
                    'Ønsker du at følge med i de svenske løb, når du har købt en Lynspil eller har spillet på de store svenske puljer, kan du følge med i ATG Nordic live. Der fokuseres på kyndige spiltips og grundige analyser med svensk kommentering. På denne kanal er det muligt at se både svenske og danske løb.',
            },
        },
    },
    StreamVideo: {
        checkboxLabel: 'Åben fra start',
    },
    RaceType: {
        trot: 'Trav',
        gallop: 'Galop',
        monte: 'Monté',
    },
    SpilklubSection: {
        title: 'SpilKlub25',
        description:
            'Spil med på eksperternes kuponer via SpilKlub25. Køb andele og lad eksperterne klare resten, du deler indsats og gevinst med de andre deltagere.',
        buttonText: 'Alle klubber',
    },
    QuickPick: {
        label: 'Lynspil',
        title: 'Lynspil gør det simpelt for alle spillere',
        description:
            'Med et Lynspil kan du spille med om millionerne hver eneste uge, uden du skal bekymre dig om, hvilke heste du skal vælge. Når du køber et Lynspil vælges der automatisk heste for dig, så du kan læne dig tilbage og nyde spændingen. På den måde kan du blive millionær for en flad 50’er.',
        receiptTitle: 'Dit spil er blevet placeret!',
        setAmount: 'Vælg beløb og køb din lyn-kupon',
        amount: 'Indsats',
        helpText: 'Held og lykke, du kan fremover finde kuponen under',
        Desktop: {
            title: 'Spil med om millionerne',
            description:
                'Med en Lynspil kan du spille med om millionerne hver eneste uge, uden du skal bekymre dig om, hvilke heste du skal vælge. Når du køber en Lynspil vælges der automatisk heste for dig, så du kan læne dig tilbage og nyde spændingen. På den måde kan du blive millionær for en flad 50’er.',
        },
        Mobile: {
            title: 'Spil med om millionerne',
            description:
                'Når du køber en Lynspil vælges hestene automatisk for dig. Du skal blot trykke på Køb Lynspil og vælge det beløb du vil spille for. ',
        },
        amountPlaceholder: 'Indtast beløb',
        buttonPlaceholder: 'Vælg beløb',
        minAmount: 'Minimum beløb er 50 Kr.',
        popular: 'Ugens mest populære Lynspil',
        today: 'Dagens Lyn-spil',
        nextDays: 'Andre Lyn-spil',
        homepage: {
            title: 'Køb en lyn kupon',
            description: `Når du køber en lyn kupon vælges hestene automatisk for dig. Du skal blot trykke på <b>Køb kupon</b> og vælge det beløb du vil spille for.`,
            allButton: 'Alle lynspil',
        },
    },
    Feedback: {
        question: 'Hey du. Hvad synes du om Bet25 heste?',
        wauw: 'Wauw',
        nice: 'Nice',
        mmm: 'Mmm',
        meh: 'Meh',
        pff: 'Pff',
        thanks: 'Tak for din feedback!',
        label: 'Ønsker du at uddybe din utilfredshed?',
        writeHere: 'Skriv her',
        desktop: {
            header: 'Hjælp os med at blive bedre',
            secondParagraph:
                'Bet25 heste ønsker at nytænke spil på hestevæddeløb, og generelt er brugeroplevelsen et nøgleord i vores produkt. Derfor vil vi gerne samarbejde med brugerne, da vi mener, at brugernes inddragelse vil skabe et bedre produkt. Hvis du har forslag eller idéer til, hvordan vi kan blive endnu bedre, hører vi således meget gerne fra dig.',
            name: 'Navn',
            email: 'E-mail',
            feedback: 'Besked',
            send: 'send',
            successfullySentMessage: 'Tilbagemelding er blevet sendt',
        },
    },
    Article: {
        createdAt: 'Skrevet d.',
        noArticlesAvailable: 'Der er ingen artikler endnu',
        shareArticle: 'Del nyhed',
        gameTypes: 'Spilleformer',
        subscribe: {
            buttonText: 'Tilmeld dig her',
            alreadySubsribedMessage: 'Du er tilmeldt',
            successMessage: 'Du er nu tilmeldt!',
        },
    },
    History: {
        active: 'Aktive',
        finished: 'Afsluttede',
        retail: 'Butik',
        winAmount: 'Gevinst',
        Reserv: {
            show: 'Vis reserver',
            hide: 'Vis heste',
        },
        retailPayoutHasWin: 'Overfør gevinst til spillekonto',
    },
    HowToPlay: {
        Section1: {
            title: 'Vælg bane, spiltype, løb og hest.',
            description:
                'Hos Bet25 heste kan du nemt og hurtigt sætte et spil. Du skal blot vælge banen, spiltypen og det løb, som du ønsker at spille på. Ønsker du at spille V-løb, som eksempelvis V-75 eller V-86 med de store millionpuljer, skal du huske at vælge heste til alle løb.',
        },
        Section2: {
            title: 'Hvilken hest skal jeg vælge?',
            description:
                'Ønsker du hjælp til at vælge din hest, kan du se nærmere på vores statistikker. På forsiden kan du få overordnede informationer omkring hesten. Her kan du eksempelvis se, hvor mange penge den har vundet i præmie, og hvordan den har præsteret i dens seneste fem løb. Når du har valgt den hest, du ønsker at spille, trykker du blot på hestens nummer i venstre side.',
        },
    },
    PdfDownload: {
        courseProgram: 'Bane program',
        courseProgramDesc: 'Optakt og startliste',
        channelOverview: 'Se TV-program',
        tips: 'Udvidet spiltips',
        tipsDesc: 'Indsigt og analyse - fra trav24',
        playList: 'Spilleliste',
    },
    userProfile: {
        changeAddress: `Hvis du ændrer din adresse, skal du
                        efterfølgende indsende et bevis for den nye
                        adresse. Dette gøres i form af et billede af dit
                        nye sygesikringsbevis eller ved at sende en
                        husstandsregning til`,
    },
    bb: {
        spilklub: 'SpilKlub25',
        iconLabel: 'ny!',
        poolCard: {
            sharesLabel: {
                singular: 'andel',
                plural: 'andele',
            },
            gameWasntPlaced: 'Spillet blev ikke placeret. Beløbet refunderes',
            soldOut: 'Andele udsolgt',
            sharePrice: 'Andelspris',
            deadline: 'Deadline',
            seeMore: 'Se mere',
        },
        group: {
            leaveGroupPopupText: 'Er du sikker på, at du vil forlade holdet?',
            leaveGroup: 'Forlad hold',
            leaveGroupConfirm: 'Ja, forlad holdet',
            leaveGroupDiscard: 'Nej, bliv på holdet',
            leaveGroupNotification: 'Du har nu forladt holdet.',
            captain: 'Kaptajn',
            boughtShares: 'Købt',
            captainLeftGroup: 'Kaptajnen forlod holdet',
        },
        poolDashboard: {
            currentPoolAmount: 'Samlet beløb i holdpuljen',
            yourShare: 'Dit indskud:',
            group: 'Se holdoversigt',
            chat: 'chat',
            sharePrice: 'Andelspris:',
            deadline: 'Deadline:',
            buyShare: 'køb andel',
            amountOfShares: 'Antal andele',
            mySharesCount: 'Mine andele',
        },
        sharedLink: {
            inviteFriends: 'Inviter holdkammerater via link:',
            addLinkToEmail: 'Inviter holdkammerater via e-mail',
            linkCopied: 'Link er kopieret!',
            createGroup: 'NÆSTE',
            info: 'Du kan altid tilføj flere til din gruppe, via Betbuddy området, som du finder under din konto, ved at trykke på dit brugernavn i top højre side. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        subscribeScreen: {
            createSubscription: 'Oprettelse af abonnement',
            description:
                'Vælg en eller flere spiltyper, som du vil abonnere på via dette hold. Beløbet trækkes automatisk fra din Bet25-konto, såfremt der er midler til det.',
            cancelSubscription: '',
            proceed: 'næste',
            group: 'gruppe',
            subscription: 'abonnement',
            addNew: 'Tilføj andre V-spil',
            gameLimit: 'Max. pr. spil',
            weekLimit: 'Max. spil ugentlig',
            weekMax: 'Max. ugentlig køb',
            terms: 'Regler og vilkår',
            unsubscribeNote: 'Du kan til enhver tid afmelde dit abonnement',
            applySubscription: 'opret abonnement',
        },
        betSlip: {
            youHaveToLogin: 'Du skal være logget ind for at bruge SpilKlub25',
        },
        groupList: {
            noGroupsTitle: 'Du er i øjeblikket ikke en del af en gruppe.',
            createCooperativeGamesButton: 'Opret andelsspil',
        },
        groupForm: {
            avatarsTitle: 'Tilføj spil til et eksisterende hold:',
        },
    },
    groupOverview: {
        mainTitle: 'Holdoversigt ',
        description: 'Dine holdkammerater og deres indsats',
        secondaryTitle: 'Inviter holdkammerater via link:',
        saldo: 'Samlet beløb i holdpuljen',
        captain: 'Holdkaptajn',
    },
    smartLyn: {
        unauthorized: 'Du skal logge ind først',
        atLeast1Horse: 'Vælg mindst en hest for at lave en Smart Lyn',
        atLeast1LegUnlocked: 'Du skal have mindst en åben række for at spille Smart Lyn',
    },
    Terminal: {
        topBar: {
            scanCoupon: 'Scan kupon',
            udbetaling: 'Udbetal',
        },
        notifications: {
            startSession: 'Balancen er for lav', //'At starte game scan kupon eller vindende kupon',
            startManagerSession: 'Sessionen udløb. Gentilslutning..',
            noSession: 'Dårlig anmodning. Ingen sessionsdata',
            getSessionFail: 'Fejl opstod under sessionen',
            bettingServiceError: 'Det er i øjeblikket ikke muligt at placere dette spil.',
        },
        amount: 'Saldo:',
        Checkin: {
            modalTitle: 'Hold din værdikupon under scanneren',
        },
        Checkout: {
            modalTitle: 'Tag din spillekupon',
        },
        savedCoupons: {
            mainText: 'DIN KUPON ER GEMT',
            info: 'Find din kupon i menuen oppe i venstre hjørne',
        },
        mobileBet: {
            title: 'spil i butik',
            barcodeText: 'Scan ved en Bet25 heste terminal',
            outdatedBarcodeText:
                'Løbet er allerede igang eller afsluttet derfor kan dette spil ikke placeres.',
            outdatedBarcodeSubText: '(Kuponen fjernes automatisk 1 time efter spilstop)',
            saveCoupon: 'gem kupon',
            deleteCoupon: 'slet kupon',
            listOfStores: 'Se liste over butikker',
            emptyList: 'Der er endnu ingen gemte indsatser',
            errors: {
                dateIsNotAvailable: 'Datoer er ikke tilgængelige længere',
                raceIsAlreadyStarted: 'Løbet er gået igang',
                raceListIsEmpty: 'Startlisten er tom',
                raceIsNotFound: 'Løbete blev ikke fundet',
                scratchedHorse: ' Hesten er udgået',
            },
        },
        CouponStatus: {
            overflowedWinAmount:
                'Gevinster over 14.999kr skal udbetales ved personalet og kræver fremvisning af ID oplysninger', //'Det er ikke muligt at overføre beløb over 15.000 kr til terminalen',
            transferToTerminal: 'Overfør til terminal',
            lost: 'Tabt',
            close: 'Luk',
            bet25CouponTitles: {
                won: 'SÅDAN!!',
                lost: 'AFSLUTTEDE KAMPE',
                pending: 'AFVENTER',
            },
            statusTitles: {
                win: 'Vundet',
                lost: 'Tabt',
                pending: 'Afventer',
                cancelled: 'Annulleret',
            },
        },
        PlayAndPay: {
            button: 'Print kupon',
            title: 'Ubetalt spil',
            createdAt: 'Kvittering oprettet',
            status: 'Status',
            amount: 'Indsats',
            startAt: 'Start',
            raceNum: 'Race nummer',
            events: 'Events',
            saleNotification: 'Kvittering for spil - køb ved kassen',
            terms: 'Din kupon er først aktiv når du har betalt for den ved kassen.',
            betTable: {
                place: 'Plac.',
                num: 'AFD.',
                horseNum: 'NR./HESTE',
                winner: 'VINDER',
                odds: 'Odds',
            },
            deposit: 'Indskud',
            possibleWin: 'Mulig gevinst',
            smartLyn: 'Smart Lyn',
            derbyLyn: 'Lynspil',
        },
    },
    batchBetting: {
        wrongFormatError: 'Forkert fil format',
        firstTitle: 'Systemspil',
        warning: 'Vigtigt: ',
        firstDesc:
            'Dit system skal indleveres senest 5 min. før første afdeling starter. Hvis dit system indeholder over 1000 kuponer, skal filen indleveres senest 15 min. før første afdeling starter.',
        secondTitle: 'Jokersystemet.se',
        secondDesc:
            'Med Jokersystemet kan du nemt lave dine egne reducerede systemer til alle V-spil. Se videoguiden nedenunder for at blive klogere på, hvordan du spiller reducerede systemer gennem Jokersystemet.',
        guideTitle: 'Sådan kommer du i gang',
        guideSteps: {
            first: {
                title: 'Opret en konto',
                desc: 'Det tager kun ganske få minutter.',
            },
            second: {
                title: 'Åbn spiltype',
                desc: `Start et spil ved at vælge spilformen fra forsiden på 
            <a href="https://www.jokersystemet.se/" target="_blank">
                Jokersystemet.se
            </a>
        `,
            },
            third: {
                title: 'Spiloplysninger',
                desc: 'Du får de seneste spiloplysninger fra ATG.',
            },
        },
        buttonText: 'Gå til Jokersystemet',
    },
    unhandledError: 'Uforventet fejl',
    promo: {
        footer: {
            label: 'Udbydes af Bet25 heste',
            text: 'Bet25 heste drives af 25syv A/S. Mariane Thomsens Gade 2F, 8000 Aarhus. 25syv A/S ejes af Ecosys A/S og kontrolleres af Spillemyndigheden. CVR-nummer: 30897765',
            cookie: 'Vi bruger cookies som en hjælp til at optimere brugeroplevelsen, sikkerheden samt at måle trafik. Herudover benyttes cookies med henblik på at vise individualiserede marketing indhold. Sidstnævnte cookies kan blive delt anonymt. Når du klikker eller navigerer på siden tillader du, at vi indsamler oplysninger på Bet25 Casino via cookies. For at læse hele vores cookiespolitik - tryk her.',
            offered: 'Udbydes af 25syv',
            address: 'Bet25 heste drives af 25syv A/S. Mariane Thomsens Gade, 8000 Aarhus C.',
            cvrNumber: 'CVR-nummer',
            permission: 'Tilladelse fra og under tilsyn af Spillemyndigheden.',
            call: 'Ring til',
            testYourself: 'Test dig selv',
            registration: 'Opret registrering',
            copyright: '© 2020 25syv A/S.\nAlle rettigheder forbeholdes.',
            addictiveGame: 'Spil kan være vanedannende, spil altid med omtanke.',
        },
    },
    support: {
        contactSupport: 'Kontakt os enten via support@bet25.dk eller via chatten.',
        ctaBtn: 'Start chat',
    },
};
