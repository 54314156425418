import React, { Component, Fragment } from 'react';
import Wrapper from 'ui/Wrapper';
import RaceCardHeader from './RaceCardHeader';
import RaceCardSubHeader from './RaceCardSubHeader';
import Game from 'features/Game';
import RaceInfo from './RaceInfo';
import ShortStatistic from 'features/TrackPage/components/RaceCard/Mobile/ShortStatistic';
import GameHeader from 'features/Game/GameHeader';
import { RaceCardWrapper } from 'features/RaceCard/styled';
import DetailedStatisticTable from 'features/TrackPage/components/RaceCard/Desktop/DetailedStatisticTable';
import { byPlatform, forDesktop, forMobile } from 'utils/platforms';
import PoolInformation from 'features/RaceCard/PoolInformation';
import { COLORS, BACKGROUNDS } from 'themes';
import PostPositionDelimiter from 'features/TrackPage/components/RaceCard/Desktop/PostPositionDelimiter';
import Spinner from 'ui/Spinner';
import sortable from 'ui/Sortable';
import AISREST from 'features/AISDataProvider/AISREST';
import withManualReserves from 'features/RaceCard/withManualReserves';
import { isPoolEmpty } from 'features/TrackPage/components/PoolOverview/PoolOverviewRow';
import SpilTips from 'features/SpilTips';
import { BlobPdf, CMSPdf } from './PdfDownload';

@withManualReserves
class RaceCard extends Component {
    constructor() {
        super();

        this.state = {
            allStatsExpanded: false,
            statsExpanded: false,
            trioCombination: [],
            trioOdds: 0,
            statsMounted: [], // values are ids of starts which have mounted stats table
            statsShown: [], // values are ids of starts which have displayed detailed stats table
            componentCrashed: false,
        };
    }

    componentDidCatch(error, info) {
        console.log(error, info);
        this.setState({ componentCrashed: true });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.race &&
            prevProps.race &&
            prevProps.race.raceNumber !== this.props.race.raceNumber
        ) {
            forDesktop(
                this.setState({
                    statsMounted: [],
                    statsShown: [],
                })
            );
        }
    }

    showQuickPickAmountModal = quickPickPoolRow => {
        const { showQuickPickAmountModal, auth, serverTime, derbyLynBet } = this.props;

        showQuickPickAmountModal({
            auth,
            serverTime,
            derbyLynBet,
            item: quickPickPoolRow,
        });
    };

    addBet = (startNr, betValue) => {
        const { race, selectedProduct, selectedDate, addBet, track } = this.props;

        addBet(selectedDate, track.code, selectedProduct.id, race.index, startNr, betValue);
    };
    /**
     * @param {number} row    Number from 1 to 3
     */
    markTrioRow = row => {
        const { race, selectedDate, track, markTrioRow } = this.props;
        markTrioRow(selectedDate, track.code, race.index, row);
    };

    /**
     * @param {number} row    Number from 1 to 3
     */
    unmarkTrioRow = row => {
        const { race, selectedDate, track, unmarkTrioRow } = this.props;
        unmarkTrioRow(selectedDate, track.code, race.index, row);
    };

    /**
     * @param {number} row    Number from 1 to 3
     */
    markKombRow = row => {
        const { race, selectedDate, track, markKombRow } = this.props;
        markKombRow(selectedDate, track.code, race.index, row);
    };

    /**
     * @param {number} row    Number from 1 to 3
     */
    unmarkKombRow = row => {
        const { race, selectedDate, track, unmarkKombRow } = this.props;
        unmarkKombRow(selectedDate, track.code, race.index, row);
    };

    removeBet = (startNr, betValue) => {
        const { race, selectedProduct, selectedDate, track, removeBet } = this.props;

        removeBet(selectedDate, track.code, selectedProduct.id, race.index, startNr, betValue);
    };

    commitTrioCombination = async (start1, start2, start3) => {
        const { race, selectedDate, trackId } = this.props;
        const trioOdds = await AISREST.fetchTrioOdds(
            selectedDate,
            trackId,
            race.raceNumber,
            start1,
            start2,
            start3
        );
        this.setState({
            trioCombination: [start1, start2, start3],
            trioOdds: trioOdds.data,
        });
    };

    togglePopup = start => {
        if (start.horseStat) {
            this.props.showStatisticPopup({
                start,
                race: this.props.race,
                bodyScrollLockDelay: 500,
            });
        }
    };

    toggleDetailedStatsTable = (start, cb) => {
        const stat = start.horseStat;
        //@see https://ecosys.atlassian.net/browse/DER-2556
        if (
            !stat.current &&
            !stat.pastPerformances &&
            !stat.previous &&
            !stat.total &&
            !stat.totalCurrentDistance &&
            !stat.totalSlowTrackState
        )
            return null;

        if (start.horseStat) {
            if (!this.isStatsMounted(start)) {
                this.mountDetailedStats(start, () =>
                    setTimeout(() => this.showDetailedStats(start, cb), 100)
                );
            } else if (!cb) {
                setTimeout(() => this.hideDetailedStats(start, cb), 100);
            } else {
                setTimeout(() => this.showDetailedStats(start, cb), 100);
            }

            const expanded = this.isStatsShown(start);
            expanded && this.hideDetailedStats(start);
            // collapse handler will be called by onAnimationFinished callback
        }
    };

    onExpandAllStatsHandler = cb => {
        if (this.state.allStatsExpanded) {
            this.setState({
                allStatsExpanded: false,
                statsShown: [],
            });
        } else {
            this.setState(
                {
                    allStatsExpanded: true,
                },
                () => {
                    this.expandAllStats(cb);
                }
            );
        }
    };

    expandAllStats = cb => {
        const startNumbers = this.props.starts.map(start => start.startNr);

        this.setState({ statsMounted: startNumbers }, () => {
            setTimeout(() => {
                this.setState(
                    {
                        statsShown: startNumbers,
                    },
                    cb
                );
            }, 100);
        });
    };

    mountDetailedStats = (start, onMounted) => {
        let { statsMounted } = this.state;

        this.setState(
            {
                statsMounted: [...statsMounted, start.startNr],
            },
            onMounted
        );
    };

    unmountDetailedStats = start => {
        let { statsMounted } = this.state;

        statsMounted.splice(statsMounted.indexOf(start.startNr), 1);
        this.setState({
            statsVisibility: statsMounted,
        });
    };

    showDetailedStats = (start, cb) => {
        let { statsShown } = this.state;

        this.setState(
            {
                statsShown: [...statsShown, start.startNr],
            },
            cb && cb(start.startNr)
        );
    };

    hideDetailedStats = start => {
        let { statsShown } = this.state;

        statsShown.splice(statsShown.indexOf(start.startNr), 1);
        this.setState({
            statsShown: statsShown,
        });
    };

    handleStatsAnimationFinish = start => {
        const collapsed = !this.isStatsShown(start);
        collapsed && this.unmountDetailedStats(start);
    };

    isStatsMounted = start => this.state.statsMounted.indexOf(start.startNr) !== -1;

    isStatsShown = start => this.state.statsShown.indexOf(start.startNr) !== -1;

    onTriggerStatisticHandler = status => {
        this.setState({
            statsExpanded: status,
        });
    };

    showStatisticPopup = byPlatform(
        start => this.togglePopup(start),
        start => this.toggleDetailedStatsTable(start)
    );

    render() {
        const {
            race,
            starts,
            selectedProduct,
            selectedDate,
            poolOverview,
            betTable,
            bet,
            track,
            trackId,
            shoeInfo,
            toggleSortingHandler,
            sortingStatuses,
            showReplayPopup,
            disabledRaceNumbers,
            rawPool,
            addReserve,
            removeReserve,
            reserveModeEnabled,
            raceReserves,
            isMultitrack,
            countryCode,
            selectedRaceDay,
        } = this.props;

        const { componentCrashed } = this.state;

        if (componentCrashed) {
            return null;
        }

        if (!race) {
            return <Spinner />;
        }

        const trackName = isMultitrack ? race?.hostTrack?.domesticText : track.name;

        const isTrot = race.trotRaceInfo && race.trotRaceInfo.startMethod !== null;
        const startDateTime = selectedDate + ' ' + race.postTime;
        const startDateTimeUTC = selectedDate + ' ' + race.postTimeUTC;

        const poolEmpty = isPoolEmpty(poolOverview, race.raceNumber);

        const showTips = ['DK', 'SE', 'NO'].includes(countryCode);

        return (
            <Wrapper padding="0px" white>
                {forMobile(<RaceInfo race={race} trackName={trackName} />)}

                <RaceCardWrapper padding="0px" margin="20px" rounded="10">
                    <RaceCardHeader
                        key={this.props.race.raceNumber}
                        trackName={trackName}
                        serverTime={this.props.serverTime}
                        from={startDateTime}
                        postTimeUTC={startDateTimeUTC}
                        onTriggerStatistic={this.onTriggerStatisticHandler}
                        product={selectedProduct}
                        race={this.props.race}
                        date={this.props.date}
                        resetBet={this.props.resetBet}
                        onRaceFinished={this.props.onRaceFinished}
                        markTrioRow={this.markTrioRow}
                        unmarkTrioRow={this.unmarkTrioRow}
                        markKombRow={this.markKombRow}
                        unmarkKombRow={this.unmarkKombRow}
                        trioMarksIndicator={this.props.trioMarksIndicator}
                        kombMarksIndicator={this.props.kombMarksIndicator}
                        reserveModeEnabled={this.props.reserveModeEnabled}
                        toggleReserveMode={this.props.toggleReserveMode}
                        onSmartLynPoolRowFetched={this.showQuickPickAmountModal}
                    />
                    {byPlatform(
                        null,
                        <RaceCardSubHeader
                            raceCardData={{ ...this.props }}
                            onExpandAllStatsHandler={this.onExpandAllStatsHandler}
                            allStatsExpanded={this.state.allStatsExpanded}
                            markTrioRow={this.markTrioRow}
                            unmarkTrioRow={this.unmarkTrioRow}
                            markKombRow={this.markKombRow}
                            unmarkKombRow={this.unmarkKombRow}
                            trioMarksIndicator={this.props.trioMarksIndicator}
                            kombMarksIndicator={this.props.kombMarksIndicator}
                            reserveModeEnabled={this.props.reserveModeEnabled}
                            toggleReserveMode={this.props.toggleReserveMode}
                            onSmartLynPoolRowFetched={this.showQuickPickAmountModal}
                            selections={this.props.selections}
                        />
                    )}
                    <Fragment>
                        <GameHeader
                            product={selectedProduct}
                            race={race}
                            gallop={race.trotRaceInfo && race.trotRaceInfo.startMethod === null}
                            toggleSortingHandler={toggleSortingHandler}
                            sortingStatuses={sortingStatuses}
                        />

                        <Wrapper padding="5px 0px">
                            {starts.map((start, index) => {
                                return (
                                    <Fragment key={index}>
                                        <PostPositionDelimiter
                                            isTrot={isTrot}
                                            start={start}
                                            starts={race.starts}
                                            productId={selectedProduct.id}
                                        />
                                        <Wrapper
                                            padding={byPlatform('5px 10px', '5px 10px 5px 20px')}
                                            style={{
                                                cursor: 'pointer',
                                                background:
                                                    index % 2 ? BACKGROUNDS.grey : COLORS.white,
                                            }}
                                        >
                                            <Game
                                                onClick={this.showStatisticPopup.bind(this, start)}
                                                start={start}
                                                product={selectedProduct}
                                                onBetAdded={this.addBet}
                                                onBetRemoved={this.removeBet}
                                                addReserve={addReserve}
                                                removeReserve={removeReserve}
                                                reserveModeEnabled={reserveModeEnabled}
                                                raceReserves={raceReserves}
                                                betTable={betTable}
                                                race={race}
                                                saleOpen={race.saleOpen}
                                                bet={bet}
                                                gallop={race?.trotRaceInfo?.startMethod === null}
                                                raceStarted={this.props.raceStarted}
                                                onRaceFinished={this.props.onRaceFinished}
                                                disableBetButton={
                                                    (selectedProduct.isVProduct() &&
                                                        disabledRaceNumbers.length > 0) ||
                                                    (rawPool[selectedProduct.id] &&
                                                        rawPool[selectedProduct.id].poolClosed)
                                                }
                                                shoeInfo={
                                                    shoeInfo && shoeInfo.get(race.raceNumber)
                                                        ? shoeInfo
                                                              .get(race.raceNumber)
                                                              .get(start.startNr)
                                                        : {
                                                              front: false,
                                                              back: false,
                                                          }
                                                }
                                            />
                                        </Wrapper>

                                        {this.state.statsExpanded &&
                                            forMobile(
                                                <Wrapper padding="0">
                                                    <ShortStatistic
                                                        gallop={
                                                            race.trotRaceInfo.startMethod === null
                                                        }
                                                        start={start}
                                                        shoeInfo={
                                                            shoeInfo &&
                                                            shoeInfo.get(race.raceNumber)
                                                                ? shoeInfo
                                                                      .get(race.raceNumber)
                                                                      .get(start.startNr)
                                                                : {
                                                                      front: false,
                                                                      back: false,
                                                                  }
                                                        }
                                                    />
                                                </Wrapper>
                                            )}

                                        {this.isStatsMounted(start) &&
                                            byPlatform(
                                                null,
                                                <DetailedStatisticTable
                                                    start={start}
                                                    gallop={race.trotRaceInfo?.startMethod === null}
                                                    race={race}
                                                    isShown={this.isStatsShown(start)}
                                                    onAnimationFinished={() =>
                                                        this.handleStatsAnimationFinish(start)
                                                    }
                                                    showReplayPopup={showReplayPopup}
                                                />
                                            )}
                                    </Fragment>
                                );
                            })}
                        </Wrapper>
                    </Fragment>
                </RaceCardWrapper>
                {/*{selectedProduct &&*/}
                {/*    typeof selectedProduct.isTrio === 'function' &&*/}
                {/*    selectedProduct.isTrio() && (*/}
                {/*        <TrioOdds*/}
                {/*            selectedProduct={selectedProduct}*/}
                {/*            race={race}*/}
                {/*            trioOdds={this.state.trioOdds}*/}
                {/*            commitTrioCombination={this.commitTrioCombination}*/}
                {/*            trioCombination={this.state.trioCombination}*/}
                {/*        />*/}
                {/*    )}*/}

                {showTips && (
                    <SpilTips
                        date={selectedDate}
                        race={race}
                        selectedProduct={selectedProduct}
                        trackId={trackId}
                        isVProduct={selectedProduct.isVProduct()}
                        raceDay={selectedRaceDay}
                    />
                )}

                {poolEmpty ? null : (
                    <PoolInformation
                        poolOverview={poolOverview}
                        race={race}
                        trackName={trackName}
                    />
                )}
                {forMobile(
                    <div>
                        <BlobPdf />
                        <CMSPdf type="tips" />
                    </div>
                )}
            </Wrapper>
        );
    }
}

export default sortable(
    RaceCard,
    ['VPercentage', 'startNr'],
    'race.starts',
    'starts',
    [],
    ['VPercentage']
);
