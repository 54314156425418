import React from 'react';
import { create } from 'zustand';
import classNames from 'classnames';

import {
    RaceCardActionsContainer,
    RaceCardActionsLeftSide,
    ActionButton,
    ReserveButton,
} from './styled';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useReservesMode from 'features/TrackPage/hooks/useReservesMode';
import useColumnSelection from 'features/TrackPage/hooks/useColumnSelection';

import TrioPickColumnButtons from '../common/TrioPickColumnButtons';
import PDFSection from 'features/TrackPage/components/PdfDownload/Desktop';

import { PRODUCT_IDS } from 'configs/products';
import getTexts from 'utils/localization';
import { FadeInSpan } from 'ui/animations/FadeIn2';
import KombPickColumnButtons from '../common/KombPickColumnButtons';
const t = getTexts();

interface AllStatsStore {
    allStatsExpanded: boolean;
    toggleAllStats(): void;
}

export const useStatsExpanded = create<AllStatsStore>()(set => ({
    allStatsExpanded: false,
    toggleAllStats: () => set(state => ({ allStatsExpanded: !state.allStatsExpanded })),
}));

const RaceCardActionsRow = () => {
    const { race, productId, racesFetched, isVProduct } = useTrackPage();
    const { selectAll, removeAllSelection, isAllPicked, combinationsCount } = useColumnSelection();

    const isTrio = productId === PRODUCT_IDS.T;
    const isKomb = productId === PRODUCT_IDS.K;

    const isRaceOpenForBets = race.saleOpen || !racesFetched;
    const showTrioButtons = isRaceOpenForBets && isTrio;
    const showKombButtons = isRaceOpenForBets && isKomb;

    const showSelectAllButton = !showTrioButtons && !showKombButtons && race?.saleOpen;

    const { toggleReservesMode, reservesMode } = useReservesMode();

    const { allStatsExpanded, toggleAllStats } = useStatsExpanded();

    return (
        <RaceCardActionsContainer>
            <RaceCardActionsLeftSide showTrioButtons={showTrioButtons}>
                {showTrioButtons && <TrioPickColumnButtons />}
                {showKombButtons && <KombPickColumnButtons />}

                <div
                    className={classNames('flex gap-5', {
                        'pl-10': !showTrioButtons && !showKombButtons,
                    })}
                >
                    {showSelectAllButton && (
                        <ActionButton onClick={selectAll} specialGreen={!!isAllPicked}>
                            {isAllPicked
                                ? t.RacingCard.controlArea.unselectAll
                                : t.RacingCard.controlArea.selectAll}
                        </ActionButton>
                    )}
                    <ActionButton onClick={toggleAllStats} active={allStatsExpanded}>
                        {t.RacingCard.controlArea.statistic}
                    </ActionButton>
                    {isVProduct && (
                        <ReserveButton onClick={toggleReservesMode} reservesMode={reservesMode}>
                            {t.RacingCard.controlArea.reserve}
                        </ReserveButton>
                    )}
                    {combinationsCount > 0 && (
                        <FadeInSpan>
                            <ActionButton onClick={removeAllSelection} specialRed>
                                {t.RacingCard.controlArea.clearAll}
                            </ActionButton>
                        </FadeInSpan>
                    )}
                </div>
            </RaceCardActionsLeftSide>

            <PDFSection />
        </RaceCardActionsContainer>
    );
};

export default RaceCardActionsRow;
