import React from 'react';
import styled from 'styled-components';
import getTexts from 'utils/localization';
import DownloadSection from './DownloadSection';
import usePdfDownload from '../usePdfDownload';
import useATGPdfDownload from '../useATGPdfDownload';
import { downloadFileByLink } from 'utils/DOM';

const t = getTexts();

const PdfWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const PdfDownload = () => {
    const { fileAvailable: tipsPDFAvailable, downloadPdf: downloadTipsPDF } = usePdfDownload({
        type: 'tips',
    });

    const { pdfAvailable: ATGPageWithPDFAvailable, PDFLink: ATGLink } = useATGPdfDownload();

    const downloadPdf = () => {
        downloadFileByLink(ATGLink, 'baneprogram.pdf', 'application/pdf');
    };

    return (
        <PdfWrapper>
            {tipsPDFAvailable && (
                <DownloadSection
                    onClick={downloadTipsPDF}
                    title={t.PdfDownload.tips}
                    description={t.PdfDownload.tipsDesc}
                />
            )}
            {ATGPageWithPDFAvailable && (
                <DownloadSection
                    onClick={downloadPdf}
                    title={t.PdfDownload.courseProgram}
                    description={t.PdfDownload.courseProgramDesc}
                />
            )}
        </PdfWrapper>
    );
};

export default PdfDownload;
