import React from 'react';

import CMSSpilTips from './CMSSpilTips';
import APISpilTips from './APISpilTips';

const SpilTips = ({ date, race, trackId, selectedProduct, isVProduct, raceDay }) => {
    const { id: product, name: productName } = selectedProduct;

    return !raceDay.hasTrMediaTips ? (
        <CMSSpilTips
            raceIndex={race.index}
            race={race}
            productName={productName}
            raceDay={raceDay}
            product={selectedProduct}
        />
    ) : (
        <APISpilTips
            date={date}
            raceNumber={isVProduct ? race.index + 1 : race.raceNumber}
            trackId={trackId}
            product={product}
            productName={productName}
            isVProduct={isVProduct}
        />
    );
};
export default SpilTips;
