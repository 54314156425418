class Payout {
    /**
     * A payout is an object of containing state of users
     * status of withdrawal operations.
     * @param {User}   user
     * @param {number} pendingBalance
     * @param {number} minOdds
     * @param {number} amount
     * @param {number} bonus
     * @param {number} rollover    Rollover simply shows how much
     *                             times you need to bet before your bonus
     *                             becomes eligible for withdrawal.
     *                             @param {number} amount
     * @param {number} remainingCashinWageringAmount
     * @param {number} remainingCreditWageringAmount
     * @param {number} totalBonus
     */
    constructor(
        user,
        pendingBalance,
        minOdds,
        amount,
        bonus,
        rollover,
        remainingCashinWageringAmount,
        remainingCreditWageringAmount,
        totalBonus
    ) {
        this.user = user;
        this.pendingBalance = pendingBalance;
        this.minOdds = minOdds;
        this.amount = amount;
        this.bonus = bonus;
        this.rollover = rollover;
        this.remainingCashinWageringAmount = remainingCashinWageringAmount;
        this.remainingCreditWageringAmount = remainingCreditWageringAmount;
        this.totalBonus = totalBonus;
    }

    /**
     * @returns {number}
     */
    calculateVoucher() {
        return this.pendingBalance - this.bonus;
    }

    /**
     * @returns {number}
     */
    getMinOdds() {
        return this.minOdds === 0 ? 1.5 : this.minOdds.toFixed(2);
    }

    /**
     * Determines if the user can make a withdraw request with specific amount
     * @param   {number|string}  amountToWithdraw
     * @returns {boolean}
     */
    isAmountAvailable(amountToWithdraw) {
        return this.amount >= parseFloat(amountToWithdraw.replace(',', '.'));
    }

    /**
     * Determines if user has bonus
     * @returns {boolean}
     */
    hasBonus() {
        return this.rollover > 0;
    }
}

export default Payout;
