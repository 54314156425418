import { Label, ProductId } from 'features/EventBoard/server/calendar';

export type SIMPLE_PRODUCT = 'V' | 'P' | 'K' | 'VP';

type SimplePoolRow = Record<SIMPLE_PRODUCT, { vpPoolInfo: VPPoolInfoItem[] }>;

type VPoolRow = Record<ProductId, VPool>;
// represents couple track races data with appropriate horses VP odds, scratched markers and some more
type ShortCoupleTrackVPHorsesInfo = Pick<VPOdds, 'scratched' | 'startNumber'> & {
    vOdds: VinnarOdds;
};

export type MultiTrackVpPoolItem = Pick<
    VPPoolInfoItem,
    'date' | 'raceNumber' | 'track' | 'trackId'
> & {
    vpOdds: ShortCoupleTrackVPHorsesInfo[];
};

type MultiTrackVpPoolInfo = {
    multiTrackVpPoolInfo: MultiTrackVpPoolItem[];
};

export type TrackPool = SimplePoolRow & VPoolRow & MultiTrackVpPoolInfo;

export interface Amount {
    currency: string;
    sum: number;
}

export interface VPPoolInfoItem {
    date: string;
    poolClosed: boolean;
    saleOpen: boolean;
    timestamp: string;
    track: Label;
    trackId: number;
    turnover: Amount;
    coupledHorsesInPool: boolean;
    coupledOddsArray: null;
    numberOfHorses: number;
    raceNumber: number;
    turnoverPlats: Amount;
    turnoverVinnare: Amount;
    vpOdds: {
        vpOdds: VPOdds[];
    };
}

export interface VPOdds {
    investmentPlats: null;
    investmentVinnare: null;
    platsOdds: {
        maxOdds: {
            odds: number;
            scratched: boolean;
        };
        minOdds: {
            odds: number;
            scratched: boolean;
        };
    };
    scratched: boolean;
    startNumber: number;
    vinnarOdds: VinnarOdds;
}

export interface VinnarOdds {
    odds: number;
    scratched: boolean;
}

// Represents the `V*` pool object
export interface VPool {
    date: string; // Date value
    poolClosed: boolean; // Indicates if the pool is closed
    saleOpen: boolean; // Indicates if the sale is open
    timestamp: string; // Timestamp value
    track: Label;
    trackId: number; // Track ID
    turnover: {
        currency: string; // Currency code
        sum: number; // Turnover value
    };
    betType: Label;
    jackpot: {
        currency: string; // Currency code
        sum: number; // Jackpot value
    };
    madeBetsQty: number; // Quantity of made bets
    markingBetLegs: {
        markingBetLeg: MarkingBetLeg[]; // Array of `markingBetLeg` objects
    };
}

// Represents the `markInfo` object inside `horseMarks`
export interface MarkInfo {
    percent: number; // Percentage value
    possibleValue: number | null; // forecast possible value
    quantity: number; // Quantity value
    scratched: boolean; // Indicates if the horse was scratched
    stakeDistribution: number; // Stake distribution value
    stakeDistributionPercent: number; // Stake distribution percentage value
    vPercentTrend: 'NOT_CHANGED'; // Trend value (always "NOT_CHANGED" in the provided JSON)
    startingNr: number; // Starting number of the horse
    notChangingTrend: boolean; // Indicates if the trend is not changing
    growingTrend: boolean; // Indicates if the trend is growing
    fallingTrend: boolean; // Indicates if the trend is falling
}

// Represents the `horseMarks` object
export interface HorseMarks {
    markInfos: MarkInfo[]; // Array of `markInfo` objects
}

// Represents the `markingBetLeg` object
export interface MarkingBetLeg {
    horseMarks: HorseMarks; // `horseMarks` object
    legNr: number; // Leg number
    luckyHorse: null;
    marksInLegQty: number;
    notExchangedSharesQty: null;
    notExchangedSystemsQty: null;
    scratchings: number[]; // Array of scratched horses in the leg
}

export const extractJackpot = (pool: VPool) => pool?.jackpot?.sum;

export const createAmountPlaceholderObject = (): Amount => ({
    currency: '',
    sum: 0,
});

export const createVPOddsPlaceholderObject = (attributes: Partial<VPOdds> = {}): VPOdds => ({
    investmentPlats: null,
    investmentVinnare: null,
    platsOdds: {
        maxOdds: {
            odds: 0,
            scratched: false,
        },
        minOdds: {
            odds: 0,
            scratched: false,
        },
    },
    scratched: false,
    startNumber: 0,
    vinnarOdds: {
        odds: 0,
        scratched: false,
    },
    ...attributes,
});
