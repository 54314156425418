import moment from 'moment';
import { SelfExclusionType } from 'common/DataObjects/UserStatus.ts';
import isNil from 'lodash/isNil';

const USER_STATUS_UNAPPROVED = 'temp';
//const USER_STATUS_OPEN = 'open';

export default class User {
    id = 0;
    userName = null;
    firstName = null;
    lastName = null;
    email = null;
    balance = null;
    status = null;
    hasNewsletterSubscription = false;
    lastLogin = null;
    depositMade = false;
    newsletter = 0;
    forcedPermission = null;
    birthDate = '';
    isRetailPinSet = false;
    retailPin = 0;
    city = '';

    /**
     * Determines if user account was verified automatically through NEM ID
     * or verified manually sending photo or health insurance document to support.
     * @returns {boolean}
     */
    isAccountApproved = () => {
        return this.status !== USER_STATUS_UNAPPROVED;
    };

    isSelfExcluded = () => {
        return [
            SelfExclusionType.day,
            SelfExclusionType.month,
            SelfExclusionType.datePeriod,
        ].includes(this.status);
    };

    /**
     *
     * @param   {Object} data
     * @returns {User}
     */
    static unserialize(data) {
        const user = new User();
        if (!data) {
            return user;
        }
        user.id = data.userId;
        user.userName = data.userName;
        user.firstName = data.firstName;
        user.lastName = data.lastName;
        user.email = data.email;
        user.balance = data.balance;
        user.status = data.status;
        user.hasNewsletterSubscription = !!data.newsletter;
        user.lastLoginUTC = data.extraInfo ? data.extraInfo.lastLogin : null;
        user.currentLoginUTC = data.extraInfo ? data.extraInfo.loginTime : null;
        user.lastLogin = data.extraInfo?.lastLogin ? this.getLastLoginDate(data) : null;
        user.depositMade = data.depositMade;
        user.newsletter = data.newsletter;
        user.forcedPermission = data.derbyPermission;
        user.retailHash = data.retailHash;
        user.address = data.address;
        user.zip = data.zip;
        user.birthDate = data.birthDate;
        user.city = data.city;
        user.cprNumber = data.cprNumber;
        user.isRetailPinSet = !isNil(data.retailPin) && data.retailPin !== 0;
        user.retailPin = data.retailPin;
        user.currency = data.currency;
        return user;
    }

    static getLastLoginDate(data) {
        let info = data.extraInfo || {},
            date = info.lastLogin ? info.lastLogin : new Date();

        return moment(date).format('HH:mm - DD.MM.YYYY');
    }
}
