import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SelfExclusion from './SelfExclusion';
import UserConductor from 'common/conductors/UserConductor';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { connect } from 'react-redux';
import { logout } from 'common/actions/authActions';
import User from 'common/DataObjects/User';
import getTexts from 'utils/localization';
import { toDateTime } from 'utils/date.ts';
import { isMobile } from 'utils/platforms';
import { showModal } from 'common/actions/uiActions';
import { hidePopup } from 'utils/navigation';
const t = getTexts();

const EXCLUSION_TYPE_24_H = '24 timer';
const EXCLUSION_TYPE_24_H_TEXT = 'Spilpause';
const EXCLUSION_TYPE_MONTH = '1 måned';
const EXCLUSION_TYPE_MONTH_TEXT = 'Midlertidig udelukkelse';
const EXCLUSION_TYPE_PERMANENT = 'Permanent';
const EXCLUSION_TYPE_PERMANENT_TEXT = 'Ubegrænset udelukkelse';

class SelfExclusionContainer extends Component {
    state = {
        exclusionDate: '',
        exclusionTime: '',
    };

    componentDidMount() {
        isMobile && this.props.enableCloseButton();

        UserConductor.getUserStatus().then(userStatus => {
            const [exclusionDate, exclusionTime] = toDateTime(userStatus.time, '-');

            this.setState({
                exclusionDate,
                exclusionTime,
            });
        });
    }

    submit = period => {
        UserConductor.excludeSelf(period)
            .then(() => {
                NotificationConductor.success(t.selfExclusion.success);

                try {
                    if (isMobile) {
                        this.props.hideModal('SELF_EXCLUSION');
                    } else {
                        hidePopup('USER_AREA_DESKTOP');
                    }

                    this.props.showModal('SELF_EXCLUDED_MODAL', 1, {
                        permanent: period === EXCLUSION_TYPE_PERMANENT,
                    });
                } catch (error) {
                    console.error(error);
                }
            })
            .catch(err => NotificationConductor.error(err.toString()));
    };

    render() {
        return (
            <SelfExclusion
                desktop={this.props.desktop}
                confirm={this.submit}
                isExcluded={this.props.user.isSelfExcluded()}
                exclusionDate={this.state.exclusionDate}
                exclusionTime={this.state.exclusionTime}
            />
        );
    }
}

const mapStateToProps = state => ({
    user: User.unserialize(state.auth.user),
});

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
        showModal: modalId => dispatch(showModal(modalId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelfExclusionContainer));

export {
    EXCLUSION_TYPE_24_H,
    EXCLUSION_TYPE_MONTH,
    EXCLUSION_TYPE_PERMANENT,
    EXCLUSION_TYPE_24_H_TEXT,
    EXCLUSION_TYPE_MONTH_TEXT,
    EXCLUSION_TYPE_PERMANENT_TEXT,
};
